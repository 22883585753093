<template>
  <div>
    <div 
      class="d-flex align-center"
      :class="{'flex-wrap': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm}"
    >
      <v-btn icon class="flex-shrink-1 flex-grow-0 ml-2" @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <h1 
        class="font text-center flex-shrink-0 flex-grow-1"
        style="width: 70%"
      >
        {{ this.title }}
        <!-- <v-icon v-if="showIcon" class="ml-3" medium color="black">{{ this.icon }}</v-icon> -->
      </h1>

      <v-spacer
        v-if="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm"
      ></v-spacer>

      <div 
        class="d-flex mt-2 align-end justify-end mr-10" 
        :style="{
          width: !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? null
          : '25%'
        }"
        v-if="!!currentUser"
      >
        <div class="d-flex">
          <p
            v-if="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm"
            class="mt-2 d-flex flex-row"
          >
            {{ currentUser.firstname }} {{ currentUser.lastname }}
          </p>
          <UserMenu
            class="ml-2"
            classd-flex
            align-end
            justify-end
            :user="currentUser"
            @logout-click="handleLogout"
          >
          </UserMenu>
        </div>
      </div>
    </div>
    <v-divider></v-divider>

    <SimplePopUp
      v-model="showConfirmLogoutPopUp"
      alert-text="Sei sicuro di voler uscire?"
      :dismissible="false"
    >
      <template v-slot:footer>
        <v-row class="pt-7 d-flex justify-space-around">
          <v-btn
            style="min-width: 30%;"
            color="error"
            @click="showConfirmLogoutPopUp = false"
          >
            No
          </v-btn>
          <v-btn
            style="min-width: 30%;"
            color="success"
            @click="logoutConfirmed"
          >
            Si
          </v-btn>
        </v-row>
      </template>
    </SimplePopUp>
  </div>
</template>

<script>
import UserMenu from '@/components/common/UserMenu.vue';
import SimplePopUp from '@/components/common/SimplePopUp.vue'
import authService from "@/services/auth/auth.service.js";
import currentUser from "@/services/currentUser.service"
import allCache from "@/services/all.cache";
import cashDeskDatabase from '@/services/cashDesks/cashDesk.database.js'
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";
import licenseSettingsService from "@/services/licenseSettings/licenseSettings.service";


export default {
  name: "TitleWithBackButton",
  components: {
    UserMenu,
    SimplePopUp
  },
  data: function () {
    return {
      currentUser: undefined,
      confirmLogout: undefined,
      showConfirmLogoutPopUp: false
    };
  },
  mounted: function() {
    let self = this
    currentUser.getUser().then((user) => {
      self.currentUser = user
    })

    currentUser.on('change', function(user) {
      self.currentUser = user
    })
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String,
      default: undefined,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    backRoute: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    goBack() {
      if (this.backRoute) {
        this.$router.push({ name: this.backRoute });
      } else {
        this.$router.go(-1);
      }
    },
    destroyVoiceflow() {
      const container = document.getElementById('voiceflow-chat');
      if (container) {
        window.voiceflow.chat.destroy(container);
      }
    },
    async handleLogout() {
      this.destroyVoiceflow()
      let openedCashDesk = await cashDeskService.getOpened()
      if(!!openedCashDesk){
        this.$delegates.snackbar('Chiudere la cassa prima di fare Logout')
        return
      }

      if (!!this.confirmLogout)
        this.showConfirmLogoutPopUp = true
      else
        await this.logoutConfirmed()

    },
    async logoutConfirmed() {
      this.showConfirmLogoutPopUp = false

      await allCache.clear();
      localStorage.clear()
      cashDeskDatabase.clear()
      licenseSettingsService.clear()
      
      authService
        .logout()
        .then(() => {
          this.$router
            .push({ name: "Login", params: {} })
            .then(() => {})
            .catch(() => {});
        })
        .catch(() => {});
    }
  },
  settings: {
    confirmLogout: {
      bind: "confirmLogout"
    }
  }
};
</script>

<style>
</style>
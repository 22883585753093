<template>
  <div>
    <div v-if="openChat" id="voiceflow-chat"></div>
  </div>
</template>

<script>
export default {
  name: 'VoiceflowChat',
  props: {
    projectId: {
      type: String,
      required: true,
    },
    versionId: {
      type: String,
      default: 'production',
    },
    stylesheetUrl: {
      type: String,
      default: 'https://exstentions-752ef99cf8f5.herokuapp.com/custom-stylesheet.css',
    },
    widgetUrl: {
      type: String,
      default: 'https://cdn.voiceflow.com/widget/bundle.mjs',
    },
    extensions: {
      type: Array,
      default: () => [],
    },
    autostart: {
      type: Boolean,
      default: true,
    },
    openChat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scriptLoaded: false, // Tracks if the script has been loaded
    };
  },
  methods: {
    initVoiceflowChat() {
      if (!this.scriptLoaded) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.widgetUrl;

        script.onload = () => {
          this.scriptLoaded = true;
          this.initializeChat();
        }

        // Append the script to the document
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(script);
      } else {
        this.initializeChat();
      }
    },
    initializeChat() {
      if (window.voiceflow && window.voiceflow.chat) {
        window.voiceflow.chat
          .load({
            verify: { projectID: this.projectId },
            url: 'https://general-runtime.voiceflow.com',
            versionID: this.versionId,
            launch: {
              event: {
                type: 'launch',
                payload: {
                  url: window.location.href,
                },
              },
            },
            render: {
              mode: 'overlay',
            },
            assistant: {
              stylesheet: this.stylesheetUrl,
              extensions: this.extensions,
            },
            autostart: this.autostart,
            allowDangerousHTML: true,
          })
          .then(() => {
            window.voiceflow.chat.proactive.push({
              type: 'text',
              payload: {
                message:
                  'Ciao! Vuoi avere qualche informazione sui nostri servizi?',
              },
            });
          });
        }
    },
    destroyVoiceflowChat() {
      const container = document.getElementById('voiceflow-chat');
      if (container) {
        window.voiceflow.chat.destroy(container);
      }
    },
  },
  watch: {
    openChat(newVal) {
      if (newVal) {
        this.initVoiceflowChat();
      } else {
        this.destroyVoiceflowChat();
      }
    }
  }
};
</script>

<style scoped>
/* Stile opzionale per il container del widget */
#voiceflow-chat {
  position: relative;
}
</style>


<template>
  <div 
    class="chat-popup" 
    id="chat"
  >
    <VoiceflowChat
      project-id="6704dd927d4190b9b12578aa"
      :open-chat="openChat"
      :extensions="[
        videoExtension, 
        timerExtension, 
        formExtension, 
        mapExtension, 
        fileUploadExtension, 
        kBUploadExtension, 
        dateExtension, 
        confettiExtension, 
        feedbackExtension
      ]"
    />
  </div>
</template>

<script>
import VoiceflowChat from '@/components/common/VoiceflowChat.vue';
import { 
  VideoExtension,
  TimerExtension,
  FormExtension,
  MapExtension,
  FileUploadExtension,
  KBUploadExtension,
  DateExtension,
  ConfettiExtension,
  FeedbackExtension
} from "@/services/chat/extensions.js"


export default {
  name: "Chat",
  components: {
    VoiceflowChat
  },
  props: {
    openChat: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      videoExtension: VideoExtension,
      timerExtension: TimerExtension,
      formExtension: FormExtension,
      mapExtension: MapExtension,
      fileUploadExtension: FileUploadExtension,
      kBUploadExtension: KBUploadExtension,
      dateExtension: DateExtension,
      confettiExtension: ConfettiExtension,
      feedbackExtension: FeedbackExtension,
    };
  },
};
</script>